<template>
  <section class="mt-4">
    <header>
      <h2>{{ 2 | stepTitle }}</h2>
    </header>

    <Stepper class="mb-4" :step="2" />

    <Step2Form />

    <Stepper class="my-4" :step="2" />
  </section>
</template>

<script>

import { mapGetters } from 'vuex';

import surveyMixin from '@/mixins/surveyMixin.js';
import stepTitleFilter from '@/filters/stepTitleFilter.js';

import Stepper from '@/views/components/Stepper.vue';
import Step2Form from '@/views/components/Steps/Step2Form.vue';

export default {
  mixins: [surveyMixin],
  components: {
    Stepper,
    Step2Form,
  },
  filters: {
    stepTitle: stepTitleFilter,
  },
  computed: {
    ...mapGetters('survey', {
      referenceYear: 'referenceYear',
    }),
  },
};

</script>

<style lang="scss">
</style>
